<template>
  <layout>
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <h4>
          {{ titulo }} - <b class="primario">Entidad</b> -
          <span class="primario"
            >Votos por Partido Político y Candidatura Independiente </span
          >
        </h4>

        <p class="mb-5">
          <b> Baja California Sur </b>
        </p>

        <p>
          El total de votos calculado y porentaje que se muestran, se refieren a los votos
          asentados en las Actas PREP hasta el momento.
        </p>

        <p class="mb-5">
          Por representación, los decimales de los porcentajes muestran sólo
          cuatro digitos. No obstante, al considera todos los decimales, suman
          100%.
        </p>

        <!-- <div class="totalVotos mb-5">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumen.total_votos_general_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumen.total_votos_general_porcentaje }}%
          </p>

          <p class="primario mb-0 font-weight-bold">Total de votos en:</p>
          <div class="grilla-votos">
            <div class="votos">
              <p class="mb-2">
                <b> La Entidad </b>
              </p>

              <h4 class="text-right">
                {{ resumen.total_votos_por_entidad_numero }}
              </h4>
            </div>
            <div class="votos">
              <p class="mb-2">
                <b> El Extranjero </b>
              </p>

              <h4 class="text-right">
                {{ resumen.total_votos_extranjero_numero }}
              </h4>
            </div>
          </div>
        </div> -->

        <div class="votosEnActasContabilizadas">
          <p class="titulo">Votos en Actas Contabilizadas</p>


        </div>
<!--
        <div class="row">
          <voto-por-partido
            v-for="(partido, index) in votos.partidos"
            :key="index"
            :partido="partido"
            :esDetalle="false"
            />
        </div> -->

        <div class="row resumenPartidos">
          <div
            class="col-12 col-mb-6 tarjeta"
            v-for="(partido, index) in votos.partidos"
            :key="index"
          >
            <div class="contenedor mb-3">


              <div class="grilla-superior">
                  <div class="logo" >
                    <img
                    v-if="partido.partido_logo !== 'SIN_LOGO'"
                    :src="partido.partido_logo"
                    alt="">

                    <span v-else>
                      <span>{{ partido.partido_nombre === 'NULOS' ? 'VOTOS NULOS' : partido.partido_nombre }}</span>
                    </span>

                  </div>
              </div>

              <div class="grilla-inferior">
                <div class="">
                  <p class="mb-0">
                    <b v-if="partido.partido_nombre !== 'CANDIDATURAS NO REGISTRADAS' && partido.partido_nombre !== 'NULOS'"
                    ><strong>Total de votos</strong></b> <br />
                   <strong>
                    {{ partido.partido_total_votos_general_numero }}
                   </strong>
                  </p>
                </div>
                <div class="porcentaje">

                </div>
                <div class="porcentaje">
                  <b>Porcentaje</b><br />
                  <strong>
                    {{ partido.partido_total_votos_general_porcentaje }} %

                  </strong>
                </div>
              </div>



            </div>

          </div>

        </div>

        <div class="mb-5">
          <p>
            Se considera la cantidad de votos asentadas en las Actas PREP de Casilla Especial de
            Representación Proporcional.
          </p>
          <p>
            Por representación, los decimales de los porcentajes muestran sólo cuatro digitos.
            No obstante, al considera todos los decimales, suman 100%.
          </p>
        </div>

        <!-- <div class="resumenDeLaVotacion mb-5">
          <p class="titulo">Resumen de la votación</p>

          <ResumenVotacion
            texto="En la Entidad"
            :numero="resumen.total_votos_por_entidad_numero"
            :porcentaje="resumen.total_votos_por_entidad_porcentaje"
            v-if="eleccion === 'G'"
          />
          <ResumenVotacion
            texto="En el Extranjero"
            :numero="resumen.total_votos_extranjero_numero"
            :porcentaje="resumen.total_votos_extranjero_porcentaje"
            v-if="eleccion === 'G'"
          />

          <div class="borde">
            <div class="d-flex ">
              <div class="icono pt-4 mr-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <ResumenVotacion
                texto="Votos acumulado"
                :numero="resumen.total_votos_por_entidad_numero"
                :porcentaje="
                  resumen.total_votos_por_entidad_porcentaje
                "
              />
              <span id="tooltip" class="mt-3">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg"
                    @click="mostrarModalVotoAcumulado"
                  />
              </span>
              <ModalBaseMovil :textoModal="votoAcumulado"  ref="modalRefVotoAcumulado" />

            </div>
            <div class="d-flex ">
              <div class="icono-suma pt-4 mt-4 mr-2 ml-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <ResumenVotacion
                texto="Votos acumulados"
                :numero="resumen.total_votos_por_entidad_numero"
                :porcentaje="
                  resumen.total_votos_por_entidad_porcentaje
                "
              />
            </div>
            <div class="d-flex ">
              <div class="icono-suma pt-4 mt-4 mr-2 ml-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <ResumenVotacion
                texto="Candidaturas no registradas"
                :numero="resumen.total_votos_candidatura_noregistrada_numero"
                :porcentaje="
                  resumen.total_votos_candidatura_noregistrada_porcentaje
                "
              />
            </div>
            <div class="d-flex">
              <div class="icono-suma pt-4 mt-3 mr-2 ml-2">
              </div>
              <ResumenVotacion
                texto="Votos Nulos"
                :numero="resumen.total_votos_nulos_numero"
                :porcentaje="resumen.total_votos_nulos_porcentaje"
              />
              <span id="tooltip" class="mt-3">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg"
                    @click="mostrarModalVotoNulo"
                  />
                </span>

              <ModalBaseMovil :textoModal="votoNulo"  ref="modalRefVotoNulo" />

            </div>

           
          </div>

          <div class="total-votos mt-5">
                <div class="d-flex justify-content-center">
                  <h5 class="text-center mr-2 mt-2">Total de votos</h5>

                      <span id="tooltip" class="info-votos">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa fa-info fa-lg "
                          @click="mostrarModalTotalVotos"
                          />

                      </span>
                      <ModalBaseMovil :textoModal="totalVotos" v-if="esVotototal" ref="modalRefVotosTotales" />



                </div>
                <div class="d-flex">
                  <div class="icono-suma ml-4 mt-2 pt-1">
                          <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>

                  <div class=" ml-5  text-center ">
                    <h2>{{ resumen.total_votos_general_numero }}</h2>
                  </div>

                </div>
                <div class="text-center ">
                  <h4>{{ resumen.total_votos_general_porcentaje }}%
                  </h4>
                </div>

          </div>         
        </div> -->

        <div class="resumenDeLaVotacion mb-5">
          <p class="titulo">Resumen de la votación</p>

          <ResumenVotacion
            texto="En la Entidad"
            :numero="resumen.total_votos_por_entidad_numero"
            :porcentaje="resumen.total_votos_por_entidad_porcentaje"
            v-if="eleccion === 'G'"
          />
          <ResumenVotacion
            texto="En el Extranjero"
            :numero="resumen.total_votos_extranjero_numero"
            :porcentaje="resumen.total_votos_extranjero_porcentaje"
            v-if="eleccion === 'G'"
          />

          <div class="borde">
            <div class="d-flex ">
              <div class="icono pt-4 mr-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <ResumenVotacion
                texto="Votos acumulado"
                :numero="resumen.total_votos_por_entidad_numero"
                :porcentaje="
                  resumen.total_votos_por_entidad_porcentaje
                "
              />
              <span id="tooltip" class="mt-3">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg"
                    @click="mostrarModalVotoAcumulado"
                  />
              </span>
              <ModalBaseMovil :textoModal="votoAcumulado"  ref="modalRefVotoAcumulado" />

            </div>
            <div class="d-flex ">
              <div class="icono-suma pt-4 mt-4 mr-0 ml-0">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
               <div class="icono pt-4 mr-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              
              <ResumenVotacion
                texto="Candidaturas no registradas"
                :numero="resumen.total_votos_candidatura_noregistrada_numero"
                :porcentaje="
                  resumen.total_votos_candidatura_noregistrada_porcentaje
                "
              />
              <ModalBaseMovil :textoModal="votoAcumulado"  ref="modalRefVotoAcumulado" />




            </div>
            <div class="d-flex ">
              <div class="icono-suma pt-4 mt-4 mr-2 ml-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <ResumenVotacion
                texto="Votos Nulos"
                :numero="resumen.total_votos_nulos_numero"
                :porcentaje="resumen.total_votos_nulos_porcentaje"
              />
              <span id="tooltip" class="mt-3">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg"
                    @click="mostrarModalVotoNulo"
                  />
                </span>

              <ModalBaseMovil :textoModal="votoNulo"  ref="modalRefVotoNulo" />
            </div>                   
          </div>

          <div class="total-votos mt-5">
                <div class="d-flex justify-content-center">
                  <h5 class="text-center mr-2 mt-2">Total de votos</h5>

                      <span id="tooltip" class="info-votos">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa fa-info fa-lg "
                          @click="mostrarModalTotalVotos"
                          />

                      </span>
                      <ModalBaseMovil :textoModal="totalVotos" v-if="esVotototal" ref="modalRefVotosTotales" />



                </div>
                <div class="d-flex">
                  <div class="icono-suma ml-4 mt-2 pt-1">
                          <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>

                  <div class=" ml-5  text-center ">
                    <h2>{{ resumen.total_votos_general_numero }}</h2>
                  </div>

                </div>
                <div class="text-center ">
                  <h4>{{ resumen.total_votos_general_porcentaje }}%
                  </h4>
                </div>

          </div>



          
        </div>


        <div
          class="votoEnElExtranjero px-4 py-2 text-center mb-5"
          v-if="eleccion === 'G'"
        >
          <font-awesome-icon icon="globe-americas" class="fa-3x mb-3" />
          <h4 class="font-weight-bold mb-3">Voto en el Extranjero</h4>

          <router-link
            to="/G/VE"
            class="btn btn-primary mx-auto d-block btn-actas px-4 w-50"
          >
            Ver Actas
          </router-link>
        </div>




        <div v-if="eleccion === 'D'">
          <!-- <DiputacionMigrante /> -->

          <AccesoRepresentacionProporcional />
          <AccesoVotoAnticipadoPorDistrito />

        </div>
        <div class="titulo mb-5">
          <h4>
            Estadística Entidad
          </h4>
        </div>
        <!-- <EstadisticaEntidad /> -->

        <estadistica-entidad />
        <lista-nominal />
        <porcentaje-participacion />


      </div>

      <detalle-eleccion
        v-if="anchoPantalla < 992"
        :resumen="gubernaturaResumenNivelEntidad"
        :votos="votos"
      />
    </div>
  </layout>
</template>
<style lang="scss" scoped>
.resumenPartidos {
  .tarjeta {
    margin-bottom: 15px;
    .contenedor {
      // border-left: 6px solid;
      background-color: #efefef;
      padding: 15px;
      .grilla-superior {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-bottom: 15px;

        img {
          max-height: 50px;
        }
      }
      .grilla-inferior {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: flex-end;
        // gap: 15px;
      }
    }
    .contenedor2 {
      //border-left: 6px solid;
      background-color: rgb(134, 68, 109, 0.15);
      padding: 15px;

      .grilla-superior {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-bottom: 15px;

        img {
          max-height: 50px;
        }
      }
      .grilla-inferior {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: flex-end;
        gap: 15px;
      }
    }
    /* .ganador {
      border-top: 2px solid #D3097F;
      border-right: 2px solid #D3097F;
      border-bottom: 2px solid #D3097F;
      color: #D3097F;
    } */
  }
}
.total-votos{

  button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  }
  h2{
    font-size: 48px;
    font-weight: 900;
    color:#582D73;
  }
  h4{
    color:#582D73;

  }
  h5{
    font-weight: 700;
  }

}
.titulo{
  h4{
    border-left: 10px solid #582D73;
    padding-left: 5px;
  }
}
.borde{
  border: 1px solid #ddd;
  border-spacing:5px;
}

.icono {
  background-color: #d3097f00;
  svg {
    color: #d3097f00;
  }
}
</style>
<script>
import Layout from '@/components/Movil/layouts/Layout';
import VotoPorPartido from '@/components/Movil/componentes/VotoPorPartido.vue';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion.vue';
import GraficaListaNominal from '@/components/Movil/componentes/GraficaListaNominal';
import DetalleEleccion from '@/components/DetalleEleccionPartidosPoliticos.vue';
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';
import DiputacionMigrante from '@/components/Movil/componentes/DiputacionMigrante.vue';
import AccesoRepresentacionProporcional from '@/components/Movil/componentes/AccesoRepresentacionProporcional.vue';
import ListaNominal from '@/components/ListaNominal.vue';
import PorcentajeParticipacion from '@/components/PorcentajeParticipacion.vue';
import AccesoVotoAnticipadoPorDistrito from '@/components/Movil/componentes/AccesoVotoAnticipadoPorDistrito.vue';
import ModalBaseMovil from '@/components/Movil/componentes/ModalBase.vue';


export default {
  name: 'VotosPorPartidosPoliticosMovil',
  components: {
    Layout,
    VotoPorPartido,
    ResumenVotacion,
    GraficaListaNominal,
    ModalBaseMovil,
    DetalleEleccion,
    EstadisticaEntidad,
    DiputacionMigrante,
    AccesoVotoAnticipadoPorDistrito,
    AccesoRepresentacionProporcional,
    ListaNominal,
    PorcentajeParticipacion,
    EstadisticaEntidad,
  },
  data() {
    return {
      random: 0,
      votos: {},
      esVotototal: true,
      votoAcumulado:
      "Es la suma de votos obtenidos por Partido Político, Coalición y Candidatura Independiente, sin incluir los votos de candidaturas no registradas ni nulos.",
      totalVotos:
      "Se refiere a la suma total de votos que realiza el sistema informático con base en los datos que muestran en cada Acta; podrás consultar la cifra del total de votos mostrados en cada Acta en la base de datos de PREP (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).",
      votoNulo:
      "El total y el porcentaje de votos para candidaturas no registradas y nulos, considera la votación en Casillas y Anticipados." ,

    };
  },
  computed: {
    titulo() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamiento';
      }
      return 'Diputaciones';
    },
    resumen() {
      if (this.$route.params.eleccion === 'G') {
        // return this.$store.getters.obtenerResumenVotosGubernatura
        return this.$store.state.gResumenNivelEntidad;
      }
      if (this.$route.params.eleccion === 'D') {
        // return this.$store.getters.obtenerResumenVotosGubernatura
        return this.$store.state.dResumenNivelEntidadConRP;
      }
      // if (this.$route.params.eleccion === 'A') {
        
        return this.$store.state.aResumenNivelEntidad;
      //}

      // return this.$store.state.dResumenNivelEntidad;
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    colorUrbano() {
      if (this.$store.state.esOscuro) {
        return 'f1b1d7';
      }
      return 'd2127e';
    },
    votosXPartidoTarjeta() {
      // if (this.$route.params.eleccion === 'G') {
      //   return this.$store.state.gVotosXPartidoTarjeta;
      // }
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXPartidoGraficas;
        //diputaciones_votos_partidos_politicos_vista_graficas_nivel_entidad
      }
      if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXPartidoTarjeta;
      }
    },
    gubernaturaResumenNivelEntidad() {
      if (this.$route.params.eleccion == 'D') {
        return this.$store.state.dResumenNivelEntidad;
      } if (this.$route.params.eleccion == 'A') {
        return this.$store.state.aResumenNivelEntidad;
      }
      return this.$store.state.gResumenNivelEntidad;
    },
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
  },
  methods: {
    mostrarModalVotoNulo() {
      this.$refs.modalRefVotoNulo.show();

    },
    mostrarModalVotoAcumulado() {
      this.$refs.modalRefVotoAcumulado.show();

    },
    mostrarModalTotalVotos() {
      this.$refs.modalRefVotosTotales.show();
    },
    setearVotos() {
      if (this.cargaTerminada) {
        if (this.eleccion === 'G') {
          this.votos = this.$store.state?.gVotosXPartidoTarjeta;
        } else if (this.eleccion === 'D') {
          this.votos = this.$store.state?.dVotosXPartidoGraficas;
        } else {
          this.votos = this.$store.state?.aVotosXPartidoGraficas;
        }
      }
    },
  },
  created() {
    this.setearVotos();
  },
  updated() {
    this.setearVotos();
  },
};
</script>

<template>
  <div>
    <VotosPorDistritosMovil class="d-lg-none" v-if="anchoPantalla < 992"/>
    <VotosPorDistritosEscritorio class="d-lg-block d-none" v-else />
  </div>
</template>

<script>
import VotosPorDistritosMovil from '@/components/Movil/vistas/VotosPorPartidoPolitico/Index.vue'
import VotosPorDistritosEscritorio from '@/components/Escritorio/vistas/VotosPorDistritos.vue'

export default {
  name: 'VotosPorDistritos',
  components: {
    VotosPorDistritosMovil,
    VotosPorDistritosEscritorio
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2024 - Entidad - Votos Por Distritos'
  }
}
</script>

<template>
    <div class="vista-escritorio">
        <Cabecera/>
        <Cuerpo>
            <div class="votos votos-por-distrito">
                <div class="votos-cabecera">
                    <div class="titulos">
                            <h2>{{ eleccion }} - <span><strong>Entidad</strong> Votos por Distrito</span></h2>
                            <h4><strong>Baja California Sur</strong></h4>
                            <p>El total de votos mostrado a nivel Entidad representa la suma del voto emitido en territorio Estatal y en el Extranjero. <br/> Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p>
                            <h3>Votos en Actas Contabilizadas</h3>
                    </div>
                    <div class="visualizar-como">
                        <h3>Visualiza como:</h3>
                        <div>
                            <a href="#" >
                                <span>
                                    <img src='@/assets/iconos-visualiza/Mapa-B.svg' alt="">
                                    <span>Gráfica</span>
                                </span>

                            </a>
                            <a href="#" class="visualizar-activo ">
                                <span>
                                    <img src='@/assets/iconos-visualiza/Tarjetas-A.svg' alt="">
                                    <span>Tarjetas</span>
                                </span>

                            </a>
                        </div>

                    </div>
                </div>
                <div class="tarjetas">
                    <ul>
                        <li>
                            <div>
                                <div class="partidos-distrito">
                                    <div class="partidos">
                                        <ul>
                                            <li>
                                                <div>
                                                    <img src="@/assets/P1.png">
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="distritos">
                                        <h3>Distritos</h3>
                                        <span>1</span>
                                    </div>
                                </div>
                                <div class="votos">
                                    <div>
                                        <h3>Votos</h3>
                                        <div>
                                            <span> <strong>21,638</strong> </span>
                                            <span>4.6973%</span>
                                        </div>
                                    </div>
                                </div>
                                <a href="#">Seleccionar</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mapa">
                    <div>
                        <div class="tabla">
                            <ul>
                                <li>
                                    <div class="distritos">
                                        <h3>Distritos Obtenidos</h3>
                                    </div>
                                    <div class="votos">
                                            <div>
                                                <h3>Votos</h3>
                                            </div>
                                            <div>
                                                Porcentaje <br/> de Votos
                                            </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="distritos">
                                        <div class="partidos">
                                            <img src="" alt="">
                                        </div>
                                        <div class="distrito">
                                            <span>0</span>
                                        </div>
                                    </div>
                                    <div class="votos">
                                        <div><span>76,832</span></div>
                                        <div class="progress">
                                            <div>
                                                <span>16.6642</span>
                                            </div>
                                            <div class="progress-bar" role="progressbar" style="width: 16%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="mapa-distritos">
                            <div class="informacion">
                                <h3>Mapa de Distritos Electorales</h3>
                                <p>El mapa resalta en los Distritos Electorales el Partido Poítico, Coalición o Candidatura Independiente que lleva Ventaja hasta el momento.</p>
                            </div>
                            <div class="mapa-bcs">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="resumen">
                    <div class="info">
                        <p>  <a href="#"><font-awesome-icon icon="info-circle" /></a> Da clic en <strong>Seleccionar</strong>  para visualizar el detalle de la votación. Puedes seleccionar hasta tres opciones.</p>
                        <a href="" class="boton-azul"> Ver detalle</a>
                    </div>
                    <div class="resumen-votacion">
                        <h3>Resumen de la votación</h3>
                        <div>
                            <div>
                                <div>
                                    <h4>Votos acomulados</h4>
                                    <h5>442,011</h5>
                                    <h6> 95.8686%</h6>
                                </div>
                            </div>
                            <div class="icono-mas">
                                   +
                            </div>
                            <div>
                                <div>
                                    <h4>Candidaturas no registradas</h4>
                                    <h5>1,609</h5>
                                    <h6>0.3489%</h6>
                                </div>
                            </div>
                            <div class="icono-mas">
                                   +
                            </div>
                            <div>
                                <h4>Nulos</h4>
                                <h5>17,439</h5>
                                <h6>3.7823%</h6>
                            </div>
                            <div class="icono-igual">
                                    =
                            </div>
                            <div class="total-de-votos">
                                <h4>Total de votos</h4>
                                <h5>461,059</h5>
                                <h6>100.000%</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <estadistica-entidad/>
            <div class="text-center">
                <p>
                    Los resultados presentados tienen un carácter informativo y no son definitivos, por tanto, no tienen efectos jurídicos.
                </p>
            </div>
        </Cuerpo>
        <Compartir />
        <PieDePagina />
    </div>
</template>

<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera'
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo'
import Compartir from '@/components/Compartir.vue'
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue'
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue'
export default {
  name: 'VotosPorDistritosEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    EstadisticaEntidad,
    Compartir,
    PieDePagina,
  },
  computed: {
      eleccion () {
          if (this.$route.params.eleccion === 'G') {
              return 'Gubernatura'
          } else if (this.$route.params.eleccion === 'D') {
              return 'Diputaciones'
          } else if (this.$route.params.eleccion === 'A') {
              return 'Ayuntamientos'
          }
      }
  }
}
</script>

<template>
  <div>
    <VotosPorPartidoPoliticoMovil class="d-lg-none" v-if="anchoPantalla < 992"/>
    <VotosPorPartidoPoliticoEscritorio class="d-lg-block d-none" v-else />
  </div>
</template>

<script>
import VotosPorPartidoPoliticoMovil from '@/components/Movil/vistas/VotosPorPartidoPolitico/Index.vue'
import VotosPorPartidoPoliticoEscritorio from '@/components/Escritorio/vistas/VotosPorPartidoPolitico.vue'

export default {
  name: 'VotosPorPartidoPolitico',
  components: {
    VotosPorPartidoPoliticoMovil,
    VotosPorPartidoPoliticoEscritorio
  },
  computed: {
    anchoPantalla () {
      // Aqui obtener el ancho de la pantalla desde Vuex, con el valor podemos usar con un v-if para que renderice uno u el otro
      return this.$store.state.anchoPantalla
    }
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2024 - Entidad - Votos Por Partidos Políticos y Candidaturas Independientes'
  }
}
</script>
